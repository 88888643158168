import React, { useEffect, useState } from 'react';
import IntroductionGoflTravelStyle from './style';
import Carousel from 'src/components/carouselCustom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import StepComponent from './stepsComponent';
import WhatOutGolferSayComponent from './whatOutGolfersSayComponent';
import { useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';
import { formatPhoneNumber } from 'src/const/contain';
import 'react-quill/dist/quill.snow.css';
import { getDetailExpert } from 'src/api/apiGetDetailExpert';
import useQueryApi from 'src/hooks/useApiQuery';
import EditerIntro from 'src/components/quillEditerIntro';
import ItemCarosel from './itemCarousel';
import { getImageApi } from 'src/api/apiGetImage';

interface IntroductionGoflTravelProps {
    customProp?: any;
    dataJsonProp?: any;
    image?: string;
    onUpdateValueInput?: (value: any) => void;
}

const IntroductionGoflTravel: React.FC<IntroductionGoflTravelProps> = (
    props,
) => {
    const { customProp, dataJsonProp, image, onUpdateValueInput } = props;
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [mainImage, setMainImage] = useState<any>();
    const type = queryParams.get('type');
    const [introExpert, setIntroExpert] = useState<any>('');
    const [reviewArr, setReviewArray] = useState<any>([]);

    const nameResConsultant = dataJsonProp?.resConsultant?.name;
    const lastName = nameResConsultant?.split(' ').slice(0, -1).join(' ');
    const emailResConsultant = dataJsonProp?.resConsultant?.email;
    const phoneNumber = dataJsonProp?.resConsultant?.telephone;
    const avatarConsultant = dataJsonProp?.resConsultant?.image;
    const companyName = dataJsonProp?.resConsultant?.companyName;
    const dataJsonImage = dataJsonProp?.mainCourse?.image;
    const tourNameTakeImage: string = dataJsonProp?.tourName || '';
    const words = tourNameTakeImage?.split(' ');
    const firstTwoWords =
        words[0] === 'Masters' ? 'Masters' : `${words[0]} ${words[1]}`;
    //id image tour event
    const idTheOpenImageInContentFullApi: any =
        customProp?.theOpenImage?.sys?.id || null;
    const idRyderCupImage: any = customProp?.theRyderCupImage?.sys?.id || null;
    const idMasterImage: any = customProp?.theMastersImage?.sys?.id || null;
    const idsolheimCupImageImage: any =
        customProp?.solheimCupImage?.sys?.id || null;

    const { data: dataNameResConsultant } = useQueryApi(
        ['nameResConsultant', nameResConsultant],
        () => getDetailExpert(nameResConsultant),
        {
            enabled: !!nameResConsultant,
        },
    );

    const getMappedArrayByCondition = (condition: any) => {
        let theImageToure = '';
        switch (condition) {
            case 'The Open':
                theImageToure = idTheOpenImageInContentFullApi;
                break;
            case 'Ryder Cup':
                theImageToure = idRyderCupImage;
                break;
            case 'Masters':
                theImageToure = idMasterImage;
                break;
            case 'Solheim Cup':
                theImageToure = idsolheimCupImageImage;
                break;
            default:
                return '';
        }
        return theImageToure;
    };
    const idImageTour = getMappedArrayByCondition(firstTwoWords);

    const { data: imageTourTheOpenData } = useQueryApi(
        ['image', idImageTour],
        () => getImageApi(idImageTour),
        {
            enabled: !!idImageTour,
        },
    );

    const imageUrlTourTheOpen = (imageTourTheOpenData as any)?.fields?.file?.url
        ? `http:${(imageTourTheOpenData as any)?.fields?.file?.url}`
        : null;

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!dataJsonImage) {
                setMainImage(image);
            }
        }, 3000);

        if (dataJsonImage) {
            setMainImage(dataJsonImage);
        }
        if (dataNameResConsultant) {
            setIntroExpert(dataNameResConsultant);
        }

        if (!dataJsonProp?.reviews) {
            setReviewArray([]);
        } else if (
            dataJsonProp?.reviews?.golf &&
            dataJsonProp?.reviews?.hotel
        ) {
            const combinerReview = [
                ...dataJsonProp?.reviews?.golf,
                ...dataJsonProp?.reviews?.hotel,
            ];
            setReviewArray(combinerReview);
        }

        return () => clearTimeout(timer);
    }, [customProp, image, dataJsonImage, type, dataNameResConsultant]);

    const introExpertText =
        introExpert?.fields?.documentation?.content[0]?.content[0]?.value;
    const contentStandard =
        customProp?.agentIntroStandard?.content[0]?.content[0]?.value;
    const signinStandard =
        customProp?.agentIntroStandard?.content[1]?.content[0]?.value;
    const contentEvent =
        customProp?.salesAgentIntroTournament.content[0]?.content[0]?.value;
    const signinEvent =
        customProp?.salesAgentIntroTournament.content[1]?.content[0]?.value;

    const introUpdateNew = dataJsonProp?.intro;

    const iconArr: Record<string, string> = {
        whyBookWithImage1: customProp?.whyBookWithImage1?.sys?.id,
        whyBookWithImage2: customProp?.whyBookWithImage2?.sys?.id,
        whyBookWithImage3: customProp?.whyBookWithImage3?.sys?.id,
        whyBookWithImage4: customProp?.whyBookWithImage4?.sys?.id,
    };

    const titleArr: Record<string, string> = {
        whyBookWithYgtTitle1: customProp?.whyBookWithYgtTitle1,
        whyBookWithYgtTitle2: customProp?.whyBookWithYgtTitle2,
        whyBookWithYgtTitle3: customProp?.whyBookWithYgtTitle3,
        whyBookWithYgtTitle4: customProp?.whyBookWithYgtTitle4,
    };

    const discriptionArr: Record<string, string> = {
        whyBookWithYourGolfTravel1: customProp?.whyBookWithYourGolfTravel1,
        whyBookWithYourGolfTravel2: customProp?.whyBookWithYourGolfTravel2,
        whyBookWithYourGolfTravel3: customProp?.whyBookWithYourGolfTravel3,
        whyBookWithYourGolfTravel4: customProp?.whyBookWithYourGolfTravel4,
    };

    const arrWhyContentNew = [1, 2, 3, 4].map((id) => ({
        id,
        icon: iconArr[`whyBookWithImage${id}`],
        title: titleArr[`whyBookWithYgtTitle${id}`],
        discription: discriptionArr[`whyBookWithYourGolfTravel${id}`],
    }));

    return (
        <IntroductionGoflTravelStyle>
            {type === 'Standard' && (
                <div>
                    {!mainImage ? (
                        <div className="viewMainImage">
                            <Skeleton.Image
                                className="imgGoflTravel"
                                active={true}
                                style={{ width: '100%', height: 413 }}
                            />
                        </div>
                    ) : (
                        <div className="viewMainImage">
                            <img
                                alt=""
                                className="imgGoflTravel"
                                src={mainImage}
                            />
                        </div>
                    )}
                </div>
            )}
            {type === 'Event' && (
                <div>
                    {!imageUrlTourTheOpen ? (
                        <div className="viewMainImage">
                            <Skeleton.Image
                                className="imgGoflTravel"
                                active={true}
                                style={{ width: '100%', height: 413 }}
                            />
                        </div>
                    ) : (
                        <div className="viewMainImage">
                            <img
                                alt=""
                                className="imgGoflTravel"
                                src={imageUrlTourTheOpen}
                            />
                        </div>
                    )}
                </div>
            )}
            <EditerIntro
                customPropEditor={customProp}
                contentEvent={contentEvent}
                contentStandard={contentStandard}
                signinEvent={signinEvent}
                signinStandard={signinStandard}
                lastName={lastName}
                nameResConsultant={nameResConsultant}
                phoneNumber={phoneNumber}
                emailResConsultant={emailResConsultant}
                introNew={introUpdateNew}
            />
            <div className="viewWhyContent">
                <div className="titleWhyContent">
                    Why book with Your Golf Travel?
                </div>
                <div className="showItemWhy">
                    {arrWhyContentNew.map((item: any, index: any) => {
                        return (
                            <WhatOutGolferSayComponent
                                imageId={item.icon}
                                title={item.title}
                                content={item.discription}
                                key={index}
                            />
                        );
                    })}
                </div>
            </div>
            {reviewArr?.length !== 0 && (
                <div className="carouselView">
                    <div className="titleWhyContent">What our golfers say</div>
                    <div className="carouselDesktop">
                        <Carousel
                            itemsToShow={2}
                            totalLength={reviewArr?.length}
                        >
                            {reviewArr?.map((item: any, index: any) => {
                                return (
                                    <ItemCarosel
                                        dataItemCarosel={item}
                                        key={index}
                                    />
                                );
                            })}
                        </Carousel>
                    </div>
                    <div className="carouselMobile">
                        <Carousel
                            itemsToShow={1}
                            totalLength={reviewArr?.length}
                        >
                            {reviewArr?.map((item: any, index: any) => {
                                return (
                                    <ItemCarosel
                                        dataItemCarosel={item}
                                        key={index}
                                    />
                                );
                            })}
                        </Carousel>
                    </div>
                </div>
            )}
            <div className="carouselView">
                <div className="titleWhyContent">Next steps</div>
                <StepComponent phone={phoneNumber} />
            </div>
            <div className="endView">
                <div className="titleWhyContent">
                    About{' '}
                    <span className="nameSeller">{nameResConsultant} </span> -
                    Your Golf Travel Expert
                </div>
                {avatarConsultant ? (
                    <div className="viewEnd">
                        <div className="commentSeller">
                            {introExpertText || ''}
                        </div>
                        <div className="avatarSignView">
                            <div className="avatarSeller">
                                {avatarConsultant ? (
                                    <div className="backgroundAvatar">
                                        <img
                                            alt=""
                                            className="avatarReals"
                                            src={avatarConsultant}
                                        />
                                    </div>
                                ) : (
                                    <FontAwesomeIcon
                                        className="avatar"
                                        icon={faCircleUser}
                                    />
                                )}
                            </div>
                            <div className="signEnd">
                                <div className="signature">
                                    {nameResConsultant}
                                </div>
                                <div>
                                    {phoneNumber && (
                                        <div className="phone">
                                            {formatPhoneNumber(phoneNumber)}
                                        </div>
                                    )}
                                    <div className="email">
                                        {emailResConsultant}
                                    </div>
                                    {companyName && <div>{companyName}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="viewEnd">
                        <Skeleton active={true} />
                    </div>
                )}
            </div>
        </IntroductionGoflTravelStyle>
    );
};

export default React.memo(IntroductionGoflTravel);
